<template>
  <WeModal v-on:close="onClose" title="Yeni Teslimat Bölgesi & Teslimat Saati">
    <div slot="body">
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="delivery-zone-tab"
            data-toggle="pill"
            href="#delivery-zone"
            role="tab"
            aria-controls="delivery-zone"
            aria-selected="true"
            >Teslimat Bölgesi</a
          >
        </li>
        <li class="nav-item ml-2" role="presentation">
          <a
            class="nav-link"
            id="delivery-time-tab"
            data-toggle="pill"
            href="#delivery-time"
            role="tab"
            aria-controls="delivery-time"
            aria-selected="false"
            >Teslimat Saatleri</a
          >
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="delivery-zone"
          role="tabpanel"
          aria-labelledby="delivery-zone-tab"
        >
          <div class="row">
            <div class="col-12 col-md-3">
              <!-- Country -->
              <div class="d-block my-3">
                <label class="custom-label">Ülke</label>
                <v-select
                  v-bind:options="region.countries"
                  v-bind:clearable="false"
                  v-model="detail.country"
                  label="name"
                ></v-select>
              </div>
              <!-- Country -->

              <!-- City -->
              <div class="d-block" v-if="detail.country">
                <label class="custom-label">Şehir</label>
                <v-select
                  v-bind:options="region.cities"
                  v-bind:clearable="false"
                  v-model="detail.city"
                  label="name"
                ></v-select>
              </div>
              <!-- City -->
            </div>

            <!-- District -->
            <div class="col-12 col-md" v-if="region.districts.length">
              <div class="row align-items-center mb-2">
                <div class="col">
                  <label for="district" class="custom-label">İlçe</label>
                </div>
                <div class="col-auto">
                  <span
                    class="btn btn-outline-warning text-dark btn-sm font-12"
                    v-on:click="selectAllDistricts"
                    >Tümünü Seç</span
                  >
                </div>
              </div>
              <select
                id="district"
                multiple="multiple"
                class="form-control custom-scrollbar select__multiple"
                v-model="detail.districts"
              >
                <option
                  v-for="district in region.districts"
                  v-bind:key="district.id"
                  v-bind:value="district.id"
                >
                  {{ district.name }}
                </option>
              </select>
            </div>
            <!-- District -->

            <!-- Neighborhood -->
            <div class="col-12 col-md" v-if="region.neighborhoods.length">
              <div class="row align-items-center mb-2">
                <div class="col">
                  <label for="neighborhood" class="custom-label">Mahalle</label>
                </div>
                <div class="col-auto">
                  <span
                    class="btn btn-outline-warning text-dark btn-sm font-12"
                    v-on:click="selectAllNeighborhoods"
                    >Tümünü Seç</span
                  >
                </div>
              </div>
              <select
                id="neighborhood"
                multiple="multiple"
                class="form-control custom-scrollbar select__multiple"
                v-model="detail.neighborhoods"
              >
                <option
                  v-for="neighborhood in region.neighborhoods"
                  v-bind:key="neighborhood.id"
                  v-bind:value="neighborhood.id"
                >
                  {{ neighborhood.name }}
                </option>
              </select>
            </div>
            <!-- Neighborhood -->
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="delivery-time"
          role="tabpanel"
          aria-labelledby="delivery-time-tab"
        >
          <WeCard
            v-bind:class="[
              deliveryTimeSelectable ? 'alert-info' : 'alert-warning',
              'mb-3',
            ]"
          >
            <template v-if="deliveryTimeSelectable">
              <i class="fas fa-info-circle mr-2"></i>
              <span>Teslimat Saatleri İlçelere eklenecektir.</span>
            </template>
            <template v-else>
              <i class="fas fa-exclamation-circle mr-2"></i>

              <span>Ülke & Şehir seçimi yapılmadı</span>
            </template>
          </WeCard>
          <WeDeliveryTime
            v-if="deliveryTimeSelectable"
            max-height="400"
            v-bind:show-submit="false"
            v-model="detail.delivery_times"
            ref="deliveryTimeRef"
          />
        </div>
      </div>

      <div class="text-center mt-4">
        <WeSubmitButton v-bind:submit-status="submit" v-on:submit="onSubmit" />
      </div>
    </div>
  </WeModal>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "DetailModal",
  data() {
    return {
      submit: false,
    };
  },
  methods: {
    ...mapActions("address", [
      "getCountries",
      "getCities",
      "getDistricts",
      "getNeighborhoods",
    ]),
    ...mapActions("deliveryZone", ["create"]),
    ...mapMutations("address", [
      "clearCountries",
      "clearCities",
      "clearDistricts",
      "clearNeighborhoods",
    ]),
    ...mapMutations("deliveryZone", ["clearDetail"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    onClose() {
      this.clearDetail();
      this.$emit("close");
    },
    selectAllDistricts() {
      this.detail.districts = helper.arrayPluck(this.region.districts, "id");
    },
    selectAllNeighborhoods() {
      this.detail.neighborhoods = helper.arrayPluck(
        this.region.neighborhoods,
        "id"
      );
    },
    onSubmit() {
      this.submit = true;
      this.create({
        onSuccess: (result) => {
          this.detail.changed = true;
          if (result && result.data && result.data.status !== "error") {
            this.showAlert(result, this.onClose);
          } else {
            this.showAlert(result);
          }
        },
        onError: () => {
          this.onClose();
        },
      });
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message, {
        onClose: callback ? callback() : null,
      });
      this.clearMessage();
    },
  },
  computed: {
    ...mapState({
      detail: (state) => state.deliveryZone,
      region: (state) => state.address,
      shared: (state) => state.shared,
    }),
    deliveryTimeSelectable() {
      return this.detail.country && this.detail.city;
    },
  },
  mounted() {
    this.submit = false;
    this.clearCountries();
    this.getCountries();
  },
  watch: {
    "detail.country": function (country) {
      this.clearCities();
      if (country) {
        this.getCities({
          id: country.id,
        });
      }
    },
    "detail.city": function (city) {
      this.clearDistricts();
      if (city) {
        this.getDistricts({
          id: city.id,
        });
      }
    },
    "detail.districts": function (districts) {
      this.detail.neighborhoods = [];
      this.clearNeighborhoods();
      if (districts && districts.length <= 1) {
        for (let i = 0; i < districts.length; i++) {
          const district = districts[i];
          this.getNeighborhoods({
            id: district,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select__multiple {
  min-height: 250px;
}
</style>
